import "./style.scss";
import { IWorkerFriend } from "@src/app/store/workerFriends/model";

import { WorkerFriendActions } from "./WorkerFriendActions";
import { WorkerFriendProfilePicture } from "./WorkerFriendProfilePicture";

export interface WorkerFriendProps {
  workerFriend: IWorkerFriend;
}

export function WorkerFriend(props: WorkerFriendProps) {
  const { workerFriend } = props;
  const { name, hasProfilePic, workerId, qualification } = workerFriend;

  return (
    <div className="worker-friend">
      <div className="friends-list-friend-info-container">
        <WorkerFriendProfilePicture name={name} hasProfilePic={hasProfilePic} workerId={workerId} />
        <div className="friends-list-friend-info">
          <p className="friends-list-friend-name">{name}</p>
          <p className="friends-list-friend-subtitle">{qualification}</p>
        </div>
      </div>
      <WorkerFriendActions workerFriend={workerFriend} />
    </div>
  );
}
