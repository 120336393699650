import { CameraSource } from "@capacitor/camera";
import { IonText } from "@ionic/react";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { useCapturePhoto } from "@src/app/utils/mediaUpload";
import { isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import Compressor from "compressorjs";
import { ChangeEvent, useRef } from "react";

import { USER_EVENTS } from "../../../constants/userEvents";

const imgCompressConfig = {
  convertSize: 2000000, // max compress size 2MB
  quality: 0.8,
};

const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};

const compressAndGetDataURL = (fileBlob: Blob) => {
  return new Promise<{
    dataUrl: string;
    fileBlob: Blob;
  }>((resolve) => {
    new Compressor(fileBlob, {
      ...imgCompressConfig,
      async success(compressedFile) {
        const compressedFileDataURL = await blobToDataURL(compressedFile);
        resolve({ dataUrl: compressedFileDataURL, fileBlob: compressedFile });
      },
      async error(error) {
        // fallback to non-compressed file
        const originalFileDataURL = await blobToDataURL(fileBlob);
        logEvent(USER_EVENTS.COMPRESS_TIMESHEET_PHOTO_FAILED, {
          originalFileBlob: `${fileBlob}`,
          error: `${error}`,
          file: originalFileDataURL,
        });
        resolve({ dataUrl: originalFileDataURL, fileBlob });
      },
    });
  });
};

const FileExplorer: React.VFC<{
  onFileSelected: (x: SelectedFile) => void;
}> = ({ onFileSelected }) => {
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const { capturePhoto } = useCapturePhoto({
    onCaptured: (capturedFiles) => {
      if (capturedFiles) {
        const { type, fileBlob } = capturedFiles;
        compressAndGetDataURL(fileBlob as Blob).then(
          ({ dataUrl: compressedDataURL, fileBlob: compressedBlob }) => {
            onFileSelected({
              file: compressedDataURL,
              type,
              fileBlob: compressedBlob,
            });
          }
        );
      }
    },
  });

  const getFileFromPhoneGallery = async (hasOpenedGallery = false) => {
    const cameraSource = hasOpenedGallery ? CameraSource.Photos : CameraSource.Camera;

    capturePhoto(undefined, cameraSource);
  };

  const getFileFromSystem = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (!event.target.files) {
      return;
    }

    const {
      target: {
        files: [file],
      },
    } = event;
    const [, type] = file.type.split("/");

    const { dataUrl: compressedDataURL, fileBlob: compressedBlob } = await compressAndGetDataURL(
      file
    );
    onFileSelected({ file: compressedDataURL, type, fileBlob: compressedBlob });
    event.target.value = "";
  };

  const handleUploadClick = (hasOpenedGallery = false) => {
    const platformIsCapacitor = isCapacitorPlatform();
    const event =
      !platformIsCapacitor || hasOpenedGallery
        ? USER_EVENTS.CHOSE_TIMESHEET_PHOTO
        : USER_EVENTS.TOOK_TIMESHEET_PHOTO;
    logEvent(event);
    if (platformIsCapacitor) {
      getFileFromPhoneGallery(hasOpenedGallery);
    } else {
      // will trigger getFileFromSystem()
      inputFileRef.current?.click();
    }
  };

  return (
    <>
      <div className="ion-text-center ion-margin file-upload-container">
        <div role={"button"} tabIndex={0} onClick={() => handleUploadClick(false)}>
          <IonText class="file-upload-lbl">Take a Photo</IonText>
        </div>
        <hr />
        <div role={"button"} tabIndex={0} onClick={() => handleUploadClick(true)}>
          <IonText class="file-upload-lbl">Choose from Library</IonText>
        </div>
      </div>
      <input
        aria-label="Upload File"
        type="file"
        style={{ position: "absolute", top: "200%", left: "200%" }}
        onChange={getFileFromSystem}
        ref={inputFileRef}
      />
    </>
  );
};

export { FileExplorer };
