import "./style.scss";
import {
  IWorkerFriendRequest,
  WorkerFriendRequestStatus,
} from "@src/app/store/workerFriends/model";

import { WorkerFriendProfilePicture } from "./WorkerFriendProfilePicture";
import { WorkerFriendRequestActions } from "./WorkerFriendRequestActions";

interface WorkerFriendRequestProps {
  workerFriendRequest: IWorkerFriendRequest;
}

export function WorkerFriendRequest(props: WorkerFriendRequestProps) {
  const { workerFriendRequest } = props;
  const { name, hasProfilePic, workerId, status } = workerFriendRequest;
  const requestIsSent = status === WorkerFriendRequestStatus.SENT;

  return (
    <div className="worker-friend">
      <div className="friends-list-friend-info-container">
        <WorkerFriendProfilePicture name={name} hasProfilePic={hasProfilePic} workerId={workerId} />
        <div className="friends-list-friend-info">
          <p className="friends-list-friend-name">{name}</p>
          <p className="friends-list-friend-subtitle">
            {requestIsSent ? "Friend request sent" : "Friend request"}
          </p>
        </div>
      </div>
      {!requestIsSent && <WorkerFriendRequestActions workerFriendRequest={workerFriendRequest} />}
    </div>
  );
}
