import { IWorkerFriendRequest } from "@src/app/store/workerFriends/model";

import { WorkerFriendRequest } from "./WorkerFriendRequest";

interface WorkerFriendRequestListProps {
  workerFriendRequestsList: IWorkerFriendRequest[];
}

export function WorkerFriendRequestList(props: WorkerFriendRequestListProps) {
  const { workerFriendRequestsList } = props;
  return (
    <div className="friends-list">
      {workerFriendRequestsList.map((workerFriendRequest: IWorkerFriendRequest) => (
        <WorkerFriendRequest
          workerFriendRequest={workerFriendRequest}
          key={`wwf_requests_${workerFriendRequest.friendRequestId}`}
        />
      ))}
    </div>
  );
}
