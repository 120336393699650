import { IonButton, IonIcon, IonSpinner } from "@ionic/react";
import { useAppSelector } from "@src/app/store";
import {
  setWorkerFriendRequestsData,
  setWorkerFriendsData,
} from "@src/app/store/workerFriends/actions";
import {
  IWorkerFriendRequest,
  WorkerFriendRequestAction,
  WorkerFriendRequestStatus,
} from "@src/app/store/workerFriends/model";
import { useToast } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { updateWorkerFriendRequest } from "./api";
import { FriendRequestState } from "./workWithFriends.types";

interface WorkerFriendRequestActionsProps {
  workerFriendRequest: IWorkerFriendRequest;
}

export function WorkerFriendRequestActions(props: WorkerFriendRequestActionsProps) {
  const { workerFriendRequest } = props;
  const { showSuccessToast, showErrorToast } = useToast();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const allReceivedWorkerFriendRequests =
    useAppSelector(
      (store) =>
        store.workerFriendsStore.workerFriendRequests[WorkerFriendRequestStatus.RECEIVED].data
    ) ?? [];
  const allCurrentWorkerFriends =
    useAppSelector((store) => store.workerFriendsStore.workerFriends.data) ?? [];
  const onClickConfirmWorkerFriendRequest = async (action: WorkerFriendRequestAction) => {
    setLoading(true);
    try {
      const result = await updateWorkerFriendRequest(workerFriendRequest.friendRequestId, action);
      logEvent(
        action === WorkerFriendRequestAction.ACCEPT
          ? USER_EVENTS.FRIEND_REQUEST_ACCEPTED
          : USER_EVENTS.FRIEND_REQUEST_DECLINED,
        {
          AgentfriendRequestId: workerFriendRequest.friendRequestId,
          Action: action,
          State: FriendRequestState.REQUESTED,
          RequesterId: workerFriendRequest.workerId,
        }
      );
      if (action === WorkerFriendRequestAction.ACCEPT) {
        if (result && result.newWorkerFriend) {
          setWorkerFriendsData(dispatch, [result.newWorkerFriend, ...allCurrentWorkerFriends]);
          showSuccessToast(`${result.newWorkerFriend.name} has been added as a friend!`);
        } else {
          showErrorToast("There was an error, please check after sometime.");
          deprecatedDoNotUseLogError({
            message: `Error while accepting a friend request - new friend data was not received `,
          });
        }
      } else {
        showSuccessToast(`${workerFriendRequest.name}'s friend request has been declined!`);
      }
      setWorkerFriendRequestsData(
        dispatch,
        WorkerFriendRequestStatus.RECEIVED,
        allReceivedWorkerFriendRequests.filter(
          (receivedWorkerFriendRequests) =>
            receivedWorkerFriendRequests.friendRequestId !== workerFriendRequest.friendRequestId
        )
      );
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Error while updating a friend request. ${JSON.stringify(error?.stack || error)}`,
      });
      showErrorToast("There was an error, please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="worker-friend-action-buttons-container">
        {loading ? (
          <IonSpinner color="primary" />
        ) : (
          <>
            <IonButton
              size="small"
              fill="clear"
              onClick={() => onClickConfirmWorkerFriendRequest(WorkerFriendRequestAction.DECLINE)}
              class="worker-friend-request-decline-button"
            >
              <IonIcon icon={closeOutline} mode="ios" data-testid="decline-icon" />
            </IonButton>
            <IonButton
              size="small"
              fill="solid"
              onClick={() => onClickConfirmWorkerFriendRequest(WorkerFriendRequestAction.ACCEPT)}
              class="worker-friend-request-accept-button"
              color="primary"
            >
              <IonIcon icon={checkmarkOutline} mode="ios" data-testid="accept-icon" />
            </IonButton>
          </>
        )}
      </div>
    </>
  );
}
