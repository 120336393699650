import { IWorkerFriend } from "@src/app/store/workerFriends/model";

import { WorkerFriend } from "./WorkerFriend";

export interface WorkerFriendListProps {
  workerFriendsList: IWorkerFriend[];
}

export function WorkerFriendList(props: WorkerFriendListProps) {
  const { workerFriendsList } = props;
  return (
    <div className="friends-list">
      {workerFriendsList.map((workerFriend) => (
        <WorkerFriend workerFriend={workerFriend} key={`wwf_friend_${workerFriend.friendId}`} />
      ))}
    </div>
  );
}
