import "./style.scss";
import { IonAvatar } from "@ionic/react";
import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import { getFSSProfilePicDistributionURL } from "@src/utils/fss.utils";
import { FC, useCallback, useEffect, useState } from "react";

import { WorkerFriendProfilePictureProps } from "./interface";

export const WorkerFriendProfilePicture: FC<WorkerFriendProfilePictureProps> = ({
  name,
  hasProfilePic,
  workerId,
  imageUrl,
}) => {
  const [distributionUrl, setDistributionUrl] = useState("");

  const fetchDistributionUrl = useCallback(async () => {
    try {
      const distributionUrl = await getFSSProfilePicDistributionURL(workerId);
      setDistributionUrl(distributionUrl);
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Error while fetching profile picture for colleague. ${JSON.stringify(
          error?.stack || error
        )}`,
      });
    }
  }, [workerId]);

  useEffect(() => {
    if (!hasProfilePic || imageUrl?.length) {
      return;
    }
    fetchDistributionUrl();
  }, [fetchDistributionUrl, hasProfilePic, imageUrl?.length, workerId]);

  return (
    <IonAvatar
      className={`worker-friend-profile-pic-avatar ${
        imageUrl?.length || (distributionUrl?.length && "worker-friend-profile-pic-avatar-border")
      }`}
      data-testid="worker-friend-profile-pic-avatar"
    >
      {imageUrl?.length || distributionUrl?.length ? (
        <img data-testid="worker-friend-image" src={imageUrl ?? distributionUrl} alt="profile" />
      ) : (
        <p data-testid="worker-friend-initial-avatar" className="worker-friend-initial-avatar">
          {name?.charAt(0)?.toUpperCase()}
        </p>
      )}
    </IonAvatar>
  );
};
