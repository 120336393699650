import { IonButton, IonSpinner } from "@ionic/react";
import { useAppSelector } from "@src/app/store";
import { setWorkerFriendsData } from "@src/app/store/workerFriends/actions";
import { IWorkerFriend } from "@src/app/store/workerFriends/model";
import { useToast } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { USER_EVENTS } from "@src/constants";
import { useState } from "react";
import { useDispatch } from "react-redux";

import { removeWorkerFriend } from "./api";
import { WorkerFriendConfirmationModal } from "./WorkerFriendConfirmationModal";

export interface WorkerFriendActionsProps {
  workerFriend: IWorkerFriend;
}

export function WorkerFriendActions(props: WorkerFriendActionsProps) {
  const { workerFriend } = props;
  const { showSuccessToast, showErrorToast } = useToast();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const allWorkerFriends = useAppSelector((store) => store.workerFriendsStore.workerFriends.data);

  const onClickConfirmWorkerFriendRemoval = async () => {
    setLoading(true);
    setOpenConfirmationModal(false);
    try {
      await removeWorkerFriend(workerFriend.friendId);
      const currentWorkerFriends = allWorkerFriends ?? [];
      logEvent(USER_EVENTS.FRIEND_REMOVED, {
        FriendUserId: workerFriend.workerId,
      });
      let newWorkerFriends: IWorkerFriend[] = [];
      newWorkerFriends = currentWorkerFriends.filter(
        (item: IWorkerFriend) => item.friendId !== workerFriend.friendId
      );
      setWorkerFriendsData(dispatch, newWorkerFriends);
      showSuccessToast(`You have removed ${workerFriend.name} from your friends list`);
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Error while removing a friend. ${JSON.stringify(error?.stack || error)}`,
      });
      showErrorToast("There was an error removing this friend, please try again.");
    }
    setLoading(false);
  };

  return (
    <>
      <div className="worker-friend-action-buttons-container">
        {loading ? (
          <IonSpinner color="primary" />
        ) : (
          <IonButton
            size="small"
            fill="clear"
            onClick={() => setOpenConfirmationModal(true)}
            class="worker-friend-remove-button"
          >
            Remove
          </IonButton>
        )}
      </div>
      {openConfirmationModal && !loading && (
        <WorkerFriendConfirmationModal
          onClickCancel={() => setOpenConfirmationModal(false)}
          workerFriend={workerFriend}
          onClickConfirm={() => onClickConfirmWorkerFriendRemoval()}
        />
      )}
    </>
  );
}
