import { IonButton, IonContent, IonModal, IonText } from "@ionic/react";
import { IWorkerFriend } from "@src/app/store/workerFriends/model";
import { FC } from "react";

interface IWorkerFriendRemovalConfirmationModalProps {
  onClickCancel: () => void;
  workerFriend: IWorkerFriend;
  onClickConfirm: () => void;
}

export const WorkerFriendConfirmationModal: FC<IWorkerFriendRemovalConfirmationModalProps> = ({
  onClickCancel,
  workerFriend,
  onClickConfirm,
}) => {
  const { name, qualification } = workerFriend;

  const message = `${name} (${qualification}) will be removed from your friends list and you will no longer see each other's shifts during booking.`;

  return (
    <IonModal isOpen={true} onWillDismiss={onClickCancel} cssClass="friend-confirmation-modal">
      <IonContent className="friend-confirmation-modal-container">
        <div className="friend-confirmation-modal-container-content">
          <IonText className="modal-title">
            <h2>Are you sure?</h2>
          </IonText>
          <IonText className="modal-subtitle">
            <p>{message}</p>
          </IonText>
        </div>
        <div className="friend-confirmation-modal-container-action">
          <IonButton
            expand="full"
            shape="round"
            fill="outline"
            color="danger"
            onClick={onClickConfirm}
          >
            Confirm
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            className="friend-confirmation-modal-cancel-action"
            fill="outline"
            onClick={onClickCancel}
          >
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
